import React, {useEffect, useState} from 'react';
import { signInWithEmailAndPassword,signOut   } from 'firebase/auth';
import { auth,signInWithGoogle,signInWithMicrosoft } from '../firebase';
import { NavLink, useNavigate,useSearchParams } from 'react-router-dom'
import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { SENDBIRD_INFO } from '../constants/constants';
import Notiflix from 'notiflix';
import {
    getFirestore,
    query,
    getDocs,
    collection,
    where,
    addDoc,
  } from "firebase/firestore";
import { db } from '../firebase';
import {Helmet} from "react-helmet-async"
import { analytic } from '../utils/Analytics';
import { useAuthState } from "react-firebase-hooks/auth";

import { StoreUserData } from '../utils/StoreUserData';
const Login = () => {
    Notiflix.Loading.remove();
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [searchParams, setSearchParams] = useSearchParams();
    const [termsChecked, setTermsChecked] = useState(false);
    const [termsErr, setTermsErr] = useState(false);
    const [showpopup, setShowpopup] = useState(searchParams.get("waitlist") ? true : false);
    const handlePopup = ()=>{
        setShowpopup(!showpopup)
    }
    const validationSchema = Yup.object({
        email: Yup.string().required("*Email is required field").email(),
        password: Yup.string().required("*Password is required field"),
      });
      const formOptions = { resolver: yupResolver(validationSchema) };
      const { register, handleSubmit, formState } = useForm(formOptions);
      const { errors } = formState;
      const [isLoading, setIsLoading] = useState(false);
 
    // const handleLogout = () => {               
    //     signOut(auth).then(() => {
    //     // Sign-out successful.
    //         navigate("/login");
    //     }).catch((error) => {
    //     // An error happened.
    //     });
    // }
    const [user, loading, error] = useAuthState(auth);

    useEffect(()=>{
        const onboarding = localStorage.getItem('onboarding');
        if(!onboarding && !user) return navigate("/onboarding");
    },[]);

    const onLogin = (e) => {
        // e.preventDefault();
        if(termsChecked){
            setTermsErr(false);
            setIsLoading(true);
            signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in
                const userCredentialData = userCredential.user;
                const q = query(collection(db, "Whitelist"), where("email", "==", email));
                getDocs(q).then((docs) => {
                    const address = email.split('@').pop()
                    if (docs.docs.length === 0 && !SENDBIRD_INFO.allowedDomains.includes(address)) {
                        addDoc(collection(db, "Waitlist"), {
                            email: userCredential.user.email,
                          }).then(() => {
                            signOut(auth).then(() => {
                                // Sign-out successful.
                                   window.location = "/login?waitlist=true"
                                    console.log("Signed out successfully")
                                }).catch((error) => {
                                // An error happened.
                                });
                          });
                          setIsLoading(false);
                
                      }else{
                        //Store User data in database
                        const qUser = query(collection(db, "Users"), where("uid", "==", userCredentialData.uid));
                        getDocs(qUser).then((docs)=>{
                            if (docs.docs.length === 0) {
                                StoreUserData(userCredentialData,db,'email')
                            }
                        });
                        analytic("login",userCredentialData.uid,"signInWithEmail");
                        navigate("/");
                        setIsLoading(false);
                      }
                   
                });
    
                
                navigate("/");
                setIsLoading(false);
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage);
                setIsLoading(false);
            });
        } else {
            setTermsErr(true);
        }
       
    }

    const handleTermsChange = (e) => {
        setTermsChecked(!termsChecked);
    }

    const handleGoogleSignIn = () => {
        if(termsChecked){
            signInWithGoogle();
            setTermsErr(false);
        } else {
            setTermsErr(true);
        }
    }

    const handleMicrosoftSignIn = () => {
        if(termsChecked){
            signInWithMicrosoft();
            setTermsErr(false);
        } else {
            setTermsErr(true);
        }
    }
 
    return(
        <>
           {(!loading && !user) ? <main className='flex justify-center w-full h-full mx-auto bg-blue-50'> 
                <Helmet>
                    <title>Login Brainstormer - AI-powered platform for enhanced brainstorming sessions</title>
                </Helmet>
                <div className="flex min-h-full w-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
                    <div className="w-full max-w-md space-y-8">
                        <div>
                            <a href="/">
                                <img className="mx-auto h-[80px] w-auto" src="/assets/images/logo_new.svg" alt="brainstromapp"/>
                            </a>

                            <h2 className="mt-6 text-center text-2xl font-bold tracking-tight text-gray-600">Sign in to your account</h2>

                        </div>
                        <form className="mt-8 space-y-6" action="#" method="POST">
                        <input type="hidden" name="remember" value="true"/>
                        <div className='flex flex-wrap justify-center'>
                            <div className="w-full sm:w-1/2 sm:pl-2">
                                <button
                                    onClick={signInWithGoogle}
                                    className="flex rounded justify-center sm:justify-around items-center w-full bg-red-700 hover:bg-red-800 text-white font-bold py-2 px-4 focus:outline-none focus:shadow-outline text-sm"
                                    type="button"
                                >
                                    <span>
                                        <img width={15} className="h-auto mr-3 sm:mr-0" src="/assets/images/googleIcon.png" alt="googlePlus"/>   
                                    </span>
                                    Sign in with Google
                                </button>
                            </div>
                            <div className="w-full mt-3 sm:mt-0 sm:w-1/2 sm:pl-2">
                                <button
                                    onClick={signInWithMicrosoft}
                                    className="flex rounded justify-center sm:justify-around items-center w-full bg-gray-900 hover:bg-gray-900 text-white font-bold py-2 px-4 focus:outline-none focus:shadow-outline text-sm"
                                    type="button"
                                >
                                    <span>
                                        <img width={15} className="h-auto mr-3 sm:mr-0" src="/assets/images/ms-white.png" alt="googlePlus"/>   
                                    </span>
                                    Sign in with Microsoft
                                </button>
                            </div>
                            <div className="relative border-b w-full h-1 flex items-center justify-center mt-7">
                                <span className=" bg-blue-50 px-2 text-gray-400">OR</span>
                            </div>
                        </div>
                        <div className="rounded-md">
                            <div className='mb-3'>
                                <label htmlFor="email-address" className="sm:text-sm opacity-70 font-bold">Email address</label>
                                <input id="email-address" name="email" {...register('email')} value={email}  onChange={(e) => setEmail(e.target.value)}  type="email" autoComplete="email" required className="shadow-sm relative block w-full rounded-md border-0 py-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6" placeholder="Enter email address"/>
                                <span className='text-red-500 sm:text-xs'>{errors.email?.message}</span>
                            </div>
                                
                            <div className=''>
                                <label htmlFor="password" className="sm:text-sm opacity-70 font-bold">Password </label>
                                <input id="password" name="password"  value={password} {...register('password')}  onChange={(e) => setPassword(e.target.value)}  type="password" autoComplete="current-password" required className="shadow-sm relative block w-full rounded-md border-0 py-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6" placeholder="Enter password"/>
                                <span className='text-red-500 sm:text-xs'>{errors.password?.message}</span>
                            </div>
                        </div>

                        {/* <div className="flex items-center justify-between">
                            <div className="flex items-center">
                            <input id="remember-me" name="remember-me" type="checkbox" className="h-4 w-4 rounded border-gray-300 text-primary-color focus:ring-primary-color"/>
                            <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">Remember me</label>
                            </div>

                            <div className="text-sm hidden">
                            <a href="#" className="font-medium text-primary-color hover:text-primary-color">Forgot your password?</a>
                            </div>
                        </div> */}

                        <div className='pb-2 flex flex-col justify-center items-start'>
                            <div className='flex items-center'>
                                <input id="tnc" name="tnc" type='checkbox' className='h-4 w-4 rounded border-gray-300 text-primary-color focus:ring-primary-color cursor-pointer' checked={termsChecked} onChange={handleTermsChange}/>
                                <label htmlFor="tnc" className="ml-2 block text-sm text-gray-900">I agree to the <a href='https://brainstormer.io/terms-conditions/' target='_blank' className='underline'>Terms and Condition.</a></label>
                            </div>
                            {termsErr && <span className='text-red-500 sm:text-xs mt-1'>Please agree to the terms and condition.</span>}
                        </div>

                        <div>
                            <button type="submit"  onClick={handleSubmit(onLogin)} className="group relative flex w-full justify-center rounded-md bg-brand-color py-2 px-3 text-md font-semibold text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 h-[40px]">
                            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                <svg className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fillRule="evenodd" d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z" clipRule="evenodd" />
                                </svg>
                            </span>
                            {isLoading ? <span className="uploadloader"></span> : 'Sign In'}
                            </button>
                        </div>
                        <div>
                            <p className="mt-2 text-center text-sm text-gray-600">
                                Don't have an account? 
                                <a href="#" className="font-medium text-brand-color hover:text-brand-color pl-1">  <NavLink to="/signup">Sign up here.</NavLink></a>
                            </p>
                        </div>
                    
                        </form>
                        
                    </div>
                </div>       

                <div className={`overlay ${showpopup ? 'flex':'hidden'}`}>
                    <div className="overlay-content relative rounded-md">
                    <span  onClick={handlePopup} className="close-icon text-lg font-bold px-2 py-0 absolute right-1 top-1 rounded-full align-middle cursor-pointer">✕</span>
                    
                    <>
                    
                        <h4 className="text-lg font-bold mb-3 text-center">Thank you for your interest in Brainstormer.</h4>
                        </>
                            <div>
                                <p className='text-center'>We are currently in a beta testing phase and have opened the app to a small but growing set of users. We have added your email address to our waitlist and will inform you via email when your account has been approved. </p>
                            </div>
                        
                    </div>
                </div>
            </main>: !loading ? navigate('/'):null}
        </>
    )
}
 
export default Login